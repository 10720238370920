.imgButton {
  border: none;
  cursor: pointer;
  appearance: none;
  background-color: inherit;
}

Button {
  font-size: 10px !important; 
}

.rTable {
  max-width: 100%;
  overflow-x: auto;
  scrollbar-width: thin !important;
  scrollbar-color: transparent transparent !important;
}

.rTable::-webkit-scrollbar {
  width: 8px !important;
}

.rTable::-webkit-scrollbar-track {
  background-color: #f1f1f1 !important;
}

.rTable::-webkit-scrollbar-thumb {
  background-color: #888 !important;
  border-radius: 6px !important;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: transparent;
}

::-webkit-scrollbar-track {
  background: transparent;
}

.shadowClass {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}

.loadingModal {
  background-color: transparent !important;
  border-width: 0px !important;
}

.ModalOpen {
  animation: openModal 0.3s ease-out forwards;
}
.ModalClosed {
  animation: closeModal 0.3s ease-out forwards;
}
.BDModalOpen {
  animation: BDopenModal 0.3s ease-out forwards;
}
.BDModalClosed {
  animation: BDcloseModal 0.3s ease-out forwards;
}

@keyframes openModal {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes closeModal {
  0% { opacity: 1; }
  100% { opacity: 0; }
}
@keyframes BDopenModal {
  0% { opacity: 0; }
  100% { opacity: 0.6; }
}

@keyframes BDcloseModal {
  0% { opacity: 0.6; }
  100% { opacity: 0; }
}

hr {
  opacity: 40%;
}

textarea {
  /* Hide default scrollbar */
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

textarea::-webkit-scrollbar {
  width: 8px;
}

textarea::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

textarea::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

.exited {
  font-weight: 700 !important;
  border: 5px solid transparent;
  border-radius: 7px;
  background-color: #e6b800 !important;
  color: white !important;
}

.running {
  font-weight: 700 !important;
  border: 5px solid transparent;
  border-radius: 7px;
  background-color: #1abc9c !important;
  color: white !important;
}

.unapproved {
  font-weight: 700 !important;
  border: 5px solid transparent;
  border-radius: 7px;
  background-color: #e6b800 !important;
  color: white !important;
}

.approved {
  font-weight: 700 !important;
  border: 5px solid transparent;
  border-radius: 7px;
  background-color: #1abc9c !important;
  color: white !important;
}

.rejected {
  font-weight: 700 !important;
  border: 5px solid transparent;
  border-radius: 7px;
  background-color: #bc1a1a !important;
  color: white !important;
}

.inputInvalid {
  border: 1px solid red !important;
  background-color: #ffabab !important;
}

.host-removed{
  background-color: rgb(212, 49, 49) !important;
  color: white !important;
}

.host-added{
  background-color: rgb(68, 179, 68) !important;
  color: white !important;
}

.host-equal{
  background-color: rgb(126, 126, 126) !important;
  color: white !important;
}

.roundLogin {
  border-radius: 10px !important;
}

.custom-modal-container {
  padding: 30px; /* Adjust the padding as needed */
  width: 1500px; /* Adjust the width as needed */
}

.circle {
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 auto; 
}

.table-skeleton {
  margin-left: 45%;
}

.around-checkbox {
  position: relative !important;
}